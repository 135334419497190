# export z _responsive-bootstarap.scss
@.gridBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    avg: 1024,
    xl: 1200,
    xxl: 1440
};

@.isBreakpointDown = (breakpointName) => (
    width = gridBreakpoints[breakpointName]
    return $(window).width() < width
)
